.download {
  display: flex;

  button {
    background-color: #1f6f45;
    color: white;
    border: white;
  }

  button.ant-btn[disabled] {
    // box-shadow: 2px 2px 4px #d2d2d2;
    border: 1px solid #d3d2d2;
  }

  button:focus,
  button:hover {
    background-color: #16aa4d;
    color: white;
  }
}
