.soluter-profile__delete {
  font-size: 24px;
  margin-bottom: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: rgb(201, 200, 200);
  transition: all 0.2s ease-in-out;
  svg{color: white;
  margin-top: 6px;}
}

.soluter-profile__delete:hover {
  background: red
}

.container-delete {
  display: flex;
  align-items: center;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
  p{
    margin: 0px;
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
  }
}

.container-delete:hover {
  p {
    color: red;
  }
}
