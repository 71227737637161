.interview__answer {
  color: #4c4b4b;
}

.interview-soluter-info {
  margin-bottom: 1.5rem;

  pre {
    margin-top: 0.25rem !important;
  }
}
