$xtra-small-spacing: 4px;
$small-spacing: 8px;
$medium-spacing: 16px;

/*
  mb-0: sem margem inferior;
  mb-xs: margem inferior muito pequena;
  mb-sm: margem inferior pequena;
  mb-md: margem inferior média;
*/
.mb {
  &-0 {
    margin-bottom: 0px !important;
  }

  &-sm {
    margin-bottom: $small-spacing;
  }

  &-xs {
    margin-bottom: $xtra-small-spacing;
  }

  &-sm {
    margin-bottom: $small-spacing;
  }

  &-md {
    margin-bottom: $medium-spacing;
  }
}

/*
  mt-0: sem margem superior;
  mt-xs: margem superior muito pequena;
  mt-sm: margem superior pequena;
  mt-md: margem superior média;
*/
.mt {
  &-0 {
    margin-top: 0px;
  }

  &-xs {
    margin-top: $xtra-small-spacing;
  }

  &-sm {
    margin-top: $small-spacing;
  }

  &-md {
    margin-top: $medium-spacing;
  }
}

/*
  ml-0: sem margem à esquerda;
  ml-xs: margem à esquerda muito pequena;
  ml-sm: margem à esquerda pequena;
  ml-md: margem à esquerda média;
*/
.ml {
  &-sm {
    margin-left: $small-spacing;
  }
  &-md {
    margin-left: $medium-spacing;
  }
}

/*
  mr-0: sem margem à direita;
  mr-xs: margem à direita muito pequena;
  mr-sm: margem à direita pequena;
  mr-md: margem à direita média;
*/
.mr {
  &-0 {
    margin-right: 0px;
  }

  &-xs {
    margin-right: $xtra-small-spacing;
  }

  &-sm {
    margin-right: $small-spacing;
  }

  &-md {
    margin-right: $medium-spacing !important;
  }
}

.no-margin {
  margin: 0px;
}

.align-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.centered-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100%
}

.space-between {
  justify-content: space-between;
}

.custom-skeleton.ant-skeleton {
  > .ant-skeleton-content {
    > .ant-skeleton-title,
    > .ant-skeleton-paragraph {
      margin: 0px;
    }
  }
}

.hoverable:hover {
  cursor: pointer;
}

.basic-tag {
  margin-top: 4px;
  background: #d263d2;
  color: white;
  padding: 1px 10px 1px 10px;
  border-radius: 25px;
}

.soluter-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  max-height: 20px;
  margin: 0px;
}

.shadowed-card.ant-card {
  box-shadow: 1px 2px 2px #d8d6d6ee;
  border-radius: 12px;
  > .ant-card-actions {
    border-radius: 0px 0px 12px 12px;
  }
}