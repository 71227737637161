.active-filters {
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  &__label {
    margin-bottom: 0px;
    margin-right: 8px;
  }
}
