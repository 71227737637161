#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.ant-menu-item a {
  color: #fff !important;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: white !important;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 40px;
  width: 40px;
  margin: 12px 12px 12px 18px;
}

.logo-header {
  display: flex;
}

.title-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -2;
  p {
    color: white;
    margin: 0;
  }
}

.active:not(.anticon, .ant-dropdown-trigger) {
  width: 100%;
  height: 64px;
  opacity: 1;
  z-index: 1;
  transition: width, height, opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .ant-badge-count {
    right: 13px;
    top: 5px;
    box-shadow: inherit;
  }
}

.notification {
  h2 {
    font-size: 1.2rem;
    padding: 5px 10px 5px 0;
    border-bottom: 2px solid #ebecf0;
  }
  a {
    color: #000000d9;
  }
}

ul.ant-dropdown-menu {
  padding: 10px 25px;
  border-radius: 10px;
  overflow: auto;
  max-height: 250px;
}

li.ant-dropdown-menu-item {
  padding: 5px 10px;
  margin-bottom: 5px;

  p {
    margin: 0;
  }
}
