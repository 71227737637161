.site-statistic-demo-card {
  padding: 30px;
  background: #ececec;
}

.district-formbutton--container {
  width: 150px;
  margin-top: 10px;
}

.district-details--no-data {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.district-details--container {
  display: flex;
  justify-content: center;
}

.district-details--titleBanner {
  display: flex;
  align-items: center;
}

.district-details--description {
  margin: 40px 20px 0px 20px;
  font-size: 17px;
}