@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 100% }
}

@keyframes fadeOut {
  from { opacity: 100% }
  to { opacity: 0 }
}

.manage-business-unit-wrapper {
  animation: .3s ease-out 0s 1 fadeIn;
}