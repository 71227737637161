.basic-table-cell {
  width: 50%;
}

.basic-table {
  width: 60%;
  .ant-table-filter-trigger-container {
    background: #eeeded;
    height: 100%;
    width: 30px;
    position: absolute;
    left: 189px;
  }
  .ant-table-filter-trigger {
    font-size: 13px;
    color: rgb(51, 50, 50);
  }
  .ant-dropdown {
    left: 429px;
  }
}

.ant-pagination {
  justify-content: flex-end;
}

@media (max-width: 1190px) {
  .basic-table {
    width: 95%;
  }
}
