.login-container {
  width: 28vw;
  height: 80vh;
  background-color: white;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 44px;
}

.login {
  min-height: 100vh;
  background: transparent linear-gradient(138deg, #1067fb 0%, #09294f 100%) 0%
    0% no-repeat padding-box;
}

.botao-login {
  width: 220px;
  height: 40px;
}
