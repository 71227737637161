.tower-schedule-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}

.scheduler-bar__label {
  text-align: center;
  margin: 0;
  font-weight: 600;
}

.scheduler-bar--empty {
  height: 25px;
  margin-top: 10px;
  margin-bottom: 5px;
  position: absolute;
}

.scheduler__crown.anticon-crown {
  margin-left: 10px;
  color: rgb(255, 180, 4);
}

.scheduler__table-actions {
  display: flex;
  margin-left: 9px;
  align-items: center;
  justify-content: center;
}

.scheduler__name-column {
  display: flex;
  align-items: center;
}

.table-shadow {
  box-shadow: 2px 1px 10px rgb(189, 189, 189);
}

.allocation__row--center {
  text-align: center;
  > div > .ant-form-item-label {
    text-align: center;
  }
}

.add-allocation-project.ant-btn-text {
  color: rgb(86, 153, 6);
  margin: 0px;
  padding: 0px;

  &:hover,
  &:focus {
    color: rgb(86, 153, 6);
    background: none;
  }
}

.add-vacation-project.ant-btn-text {
  color: #357ff4;
  margin: 0px;
  padding: 0px;

  &:hover,
  &:focus {
    color: #357ff4;
    background: none;
  }
}

.schedule-allocation {
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
}

.schedule-allocation--start {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.schedule-allocation--end {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.scheduler-cell.ant-table-cell {
  padding: 0px;
}

td.scheduler-cell.ant-table-cell {
  border-left: 1px solid #f4f4f4d4;
}

td.scheduler-cell--arrow.ant-table-cell {
  border-left: 0px;
}

.custom-scheduler {
  > * > * > * > * > * > .ant-table-sticky-scroll-bar {
    height: 11px;
  }
} 

.custom-scheduler .ant-table-row-level-0 > td {
  background: #f3f4f5;

  &:hover {
    background: #f5f5f5;
  }
}

.inactive-member > td {
  background: #f9f9f9;
}

.inactive-member > td:hover {
  background: #f7f7f7;
}

.ant-table-row-expand-icon {
  transform-origin: center !important;
}