.retry-connect {
    &__button.ant-btn {
        display: flex;
        align-items: flex-end;
    }

    &__text {
        margin-bottom: 0px;
        margin-right: 5px;
    }
}