.tower-form > .ant-form-item {
  margin-bottom: 0px;
}

.tower-projects-list.ant-collapse-item-active {
  > .ant-collapse-header > p {
    font-weight: 600;

    > span {
      background: #188fff42;
      padding: 0px 5px 0px 5px;
      border-radius: 5px;
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

.tower-projects-list__panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.react-dual-listbox {
  width: 100% !important;
  max-width: 1000px;
}

optgroup {
  position: relative;
  top: -10px;
  flex-direction: column;
  flex: auto;
  margin-top: -17px !important;
  option {
    padding-left: 0;
  }
}
