.ant-tag.mood-question {
  height: 25px;
  text-align: center;
  font-size: 14px;
  margin: 15px 0 15px 0;
  border-radius: 12px;
  font-weight: 500;
}

.mood-tag__question {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 15px;
}

.space-tags.ant-space-vertical {
  width: 100%;
}

.mood-tag__div {
  display: flex;
  justify-content: space-between;
}

.mood-tag__p {
  margin-bottom: 0px;
  font-weight: bold;
  color: rgb(75, 75, 75);

  &:hover {
    cursor: pointer;
  }
}

.mood-tag-header {
  color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ant-tag.mood-tag {
  width: 100%;
  min-height: 35px;
  text-align: center;
  font-size: 14px;
  margin: 2px;
  border-radius: 5px;
}

.ant-row.mood-tag__row {
  height: 100%;
  justify-content: space-around;
  align-content: space-between;
}

