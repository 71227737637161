.mood-row {
  height: 1000px;
}

.mood-col {
  height: inherit;
}

.mood-header {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 20px 10px;
  align-items: flex-end;
}

.graph-card {
  margin: 0px 0px 8px 8px !important;
  border-radius: 12px !important;
  min-height: 50vh;

  &-tags {
    height: 100%;
    overflow: auto;
  }

  &-line {
    height: 40%;
  }

  &-column {
    height: 59%;
  }

  &-gauge {
    height: 40%;
  }

  &-mood-progress {
    height: 59%;
  }
}

.mood-title {
  margin-bottom: 30px !important;
  text-align: center;
}

.mood-title-mood-progress {
  margin-bottom: 50px !important;
}

.tags__observation {
  display: flex;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 15px;
  align-items: center;

  &--smaller {
    color: #6a6a6a;
    margin-bottom: 40px;
  }

}
