.soluter-profile__badge {
  font-size: 24px;
  margin-bottom: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.soluter-profile__badge--high-potential.anticon-rocket {
  display: flex;
  color: white;
  background: linear-gradient(42deg, rgb(47, 84, 235), rgb(21, 178, 225));
  box-shadow: #bbbbb0 2px 2px 5px 0px;
  > svg {
    transform: rotate(45deg);
  }
}

.soluter-profile__badge--inactive.anticon-rocket {
  display: flex;
  color: white;
  background: rgb(201, 200, 200);
  > svg {
    transform: rotate(45deg);
  }
}
