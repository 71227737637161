.soluter-comment {
  background-color: #f9f9f9;
  min-height: 80px;
  border-radius: 8px;
  margin: 8px 0 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 12px;
  border: 1px #dadada;
  border-style: solid;

  &__author {
    text-align: left;
    width: 100%;
    margin-bottom: 8px;
  }

  &__text {
    padding-top: 8px;
    padding-bottom: 8px;

    &--empty {
      color: #8a8a8a;
    }
  }

  &__date {
    position: absolute;
    bottom: 4px;
    right: 8px;
    font-size: 13px;
    color: rgb(75, 75, 75);
  }
}
