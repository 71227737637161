.passport-tag {
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 2px 5px 2px 5px;
    min-width: 40px;
    > p {
        margin: 0px 10px 0px 10px;
        margin-bottom: 0;
    }
}