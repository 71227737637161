.ant-btn.comments-mood-filter__button {
  justify-content: center;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;

  &:hover {
    background: rgb(241, 241, 224);
  }

  &--active.ant-btn-link {
    background: #ffe47b;
    &:hover  {
      background: #f5d864;
    }
  }
}
