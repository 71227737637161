.tower-switch {
  &.ant-switch {
    height: 21px;
    min-width: 36px;
    &.ant-switch-checked {
      > .ant-switch-handle {
        left: calc(100% - 18px - 0px);
      }
    }

    .ant-switch-handle {
      height: 15px;
      width: 15px;
      top: 3px;
    }
  }
}
