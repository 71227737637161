.soluter-profile__badge--leader {
  display: flex;
  color: white;
  background: linear-gradient(71deg, #fea60f, rgb(255, 203, 7));
  box-shadow: #bbbbb0 2px 2px 5px 0px;
  overflow: hidden;
}

.soluter-profile__badge--inactive {
  display: flex;
  color: white;
  background: rgb(201, 200, 200);
}
