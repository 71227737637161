.advanced-search {
  &__arrow {
    right: 5%;
    transform: translateX(-50%);
    top: -15px;
    position: absolute;
    display: block;
    pointer-events: none;
    background: white;
    width: 20px;
    height: 20px;
    transform: translateY(4.24264069px) rotate(45deg);
  }

  &__row {
    margin: 20px 0px 15px 0px;

    > p {
      margin-right: 8px;
      margin-bottom: 0px;
    }
  }

  &__checkbox.ant-checkbox-wrapper {
    background: aliceblue;
    padding: 3px;
    border-radius: 2px;
  }

  &__select-operator.ant-select {
    min-width: 190px;
    margin-left: 8px;
  }
}

.soluter-card.ant-card {
  height: 110px;
  border-radius: 12px;
  box-shadow: 2px 1px 3px #d8d6d6ee;

  > .ant-card-body
    > a
    > .ant-card-meta
    > .ant-card-meta-detail
    > .ant-card-meta-title {
    margin-bottom: 0px;
  }
}

.soluter-card__skeleton {
  > .ant-skeleton-content {
    > .ant-skeleton-paragraph {
      margin: 0px !important;
    }
    > .ant-skeleton-title {
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
}

.soluters-list.ant-list {
  > .ant-list-header {
    padding-bottom: 0px;
    width: 98%;
    padding-top: 0px;
  }
}

.soluter-badge {
  &.ant-ribbon {
    font-weight: 600;
    height: 25px;
  }

  > .anticon {
    font-size: 20px;
    margin-top: 2px;
    &.anticon-rocket {
      transform: rotateZ(45deg);
      font-size: 22px;
      margin-top: 1px;
    }
  }

  &--leader:hover::after {
    content: "Líder";
  }
  
  &--high-potential:hover::after {
    content: "High Potential";
  }

  &:hover::after {
    margin-left: 8px;
    margin-right: 8px;
    height: 25px;
    width: 100px;
  }
}
