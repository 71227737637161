.soluter-profile__item {
  margin-top: 30px;
  > .ant-steps {
    margin-top: 30px;
  }

  .certification-title,
  .badges-title {
    display: flex;
    justify-content: space-between;
  }

  .badges-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: row;

    .badges-card {
      width: 10%;
      border-radius: 10px;
    }

    .badges-header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 10px 15px;
      border-radius: 10px;
      align-items: center;

      span {
        display: flex;
        height: 50px;
        width: 50px;
      }

      strong {
        line-height: 20px;
        text-align: center;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
    }
  }
}

.ant-table-sticky-scroll-bar {
  display: none !important;
}

.soluter-profile {
  &__name {
    margin-bottom: 0px;
  }

  &__missing-info {
    color: #7e7d7d;
    display: flex;
    align-items: center;
    > .anticon {
      margin-right: 8px;
      font-size: 20px;
    }
  }

  &__journey.ant-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .ant-skeleton > .ant-skeleton-content {
      display: flex;
      justify-content: center;
    }
  }

  &__compass {
    background: #ff7903;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: grid;
    justify-content: center;
    align-content: center;
    &--disabled {
      background: linear-gradient(gray, #b7b7b7);
    }
  }

  &__ellipsis {
    font-size: 36px;
    margin: 0px;
    text-align: center;
    color: rgb(46, 172, 245);
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.soluter-mood {
  display: flex;
  justify-content: space-between;

  &__legend {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  &__mood-card {
    margin-top: 5px;
  }

  &__participation {
    width: 25%;
    display: flex;
    flex-direction: column;
  }

  &__participation-count {
    text-align: center;
    margin-top: 10px;
    align-self: center;
    background-color: #007cff21;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  &__participation-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__mood-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  h2.ant-typography {
    line-height: 1.5715;
  }
}

.soluter-competence {
  &__tag {
    width: 80;
    text-align: center;
    margin-bottom: 16px;
  }

  &__link {
    margin-left: 4px;
    max-width: 200px;
    display: inline-block;
  }

  &__title-link-btn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
  }
}

.soluter-groups {
  &__col.ant-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon-group {
    margin-top: 8px;
    width: 100%;
    > .ant-spin-nested-loading > .ant-spin-container > .ant-row {
      align-items: flex-start;
    }
  }

  &__title.ant-typography {
    margin-bottom: 16px;
  }
  &__projects {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__project.ant-typography {
    margin-bottom: 10px;
    background: #ff7903;
    color: white;
    font-weight: 600;
    padding: 2px 8px 2px 8px;
    border-radius: 15px;
  }
}

.soluter-competence__actions button:not(:first-child) {
  margin-left: 0.5rem;
}

.exit-interview-checkbox.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.soluter-profile__experience {
  display: flex;
  align-items: center;
}
