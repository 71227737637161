.container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }  
  
  .tools {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: '0 8px 40px -12px rgba(0,0,0,0.3)';
   
  }

  .content {
    background: '#f3f4f5';
    box-shadow: '0 8px 40px -12px rgba(0,0,0,0.3)';
    flex: 1;
  }

  .scheduler-header {
    height: 17vh;
  }

  .scheduler {
    box-shadow: 2px 2px 20px rgba(128, 128, 128, 0.486);
    height: 80%;
  }

  @media(max-height: 799px) {
    .scheduler-header {
      height: 20vh;
      margin-bottom: 18px;
    }

    .scheduler {
      height: 80vh;
    }
  }