.error-handler {
    &__icon.anticon-warning {
        font-size: 35px;
        margin-bottom: 10px;
        color:rgb(255, 155, 0); 
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}