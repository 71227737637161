$border-radius: 12px;

#generic-card {
  margin-top: 15px;
  border: none;
  border-radius: $border-radius;
}

.generic-card {
  width: 95%;
  height: 250px;
  box-shadow: 1px 2px 2px #d8d6d6ee;

  .ant-card-body {
    padding: 0px;

    .ant-card-meta-title {
      p {
        margin-top: 18px;
      }
    }
  }

  .ant-card-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 0px 0px $border-radius $border-radius;

    > li > span a:not(.ant-btn),
    .ant-card-actions > li > span > .anticon {
      color: #1890ff;
      font-size: 16px;
    }
  }

  .ant-card-meta-avatar {
    float: left;
    padding-right: 16px;
    padding-left: 10px;
  }
}

.generic-card__meta {
  padding: 5px;
  border-radius: $border-radius $border-radius 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .ant-card-meta-detail {
    width: 100%;
  }
}

.generic-card__title {
  width: 80%;
  word-wrap: break-word;
  white-space: normal;
  color: white;
  font-weight: 600;
}

.generic-card__members {
  background: #fbfbfbdc;
  width: 60px;
  display: flex;
  height: 25px;
  border-radius: 15px;
  justify-content: center;
  margin-right: 10px;
}

.generic-card__members-number {
  margin-top: 0 !important;
  font-weight: 600;
}

.generic-card__description_custom {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 40px;
  color: rgb(105, 104, 104);
  -webkit-line-clamp: 4;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  &--empty {
    color: #8a8a8a;
  }
}

.generic-card__description_CE {
  margin: 0px 0px 0px 20px;
  font-weight: bold;
  position: relative;
  bottom: 10px;
}

.generic-card__missing-info {
  margin: 0;
}

.generic-card__skills {
  margin-left: 20px;
  margin-right: 40px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  &--empty {
    color: #8a8a8a;
  }
}

.generic-card-actions {
  padding-top: 10px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  border-top: 1px solid #f0f0f0;
}

.generic-card-actions__list {
  font-size: 17px;
  list-style: none;
  display: flex;
  padding: 0;
}

.generic-card-actions__button {
  margin: auto;
}

.generic-card__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.tower__avatar {
  > img {
    object-fit: contain !important;
    background: #ffffff59;
  }
}
