.details-card.ant-card {
    min-height: 100vh;
    border-radius: 12px;
    box-shadow: 2px 1px 3px #d8d6d6ee;
  
    > .ant-card-body > .ant-card-meta {
      display: flex;
      align-items: center;
    }
  }
  
  .details-card__tabs.ant-tabs {
    margin-top: 30px;
  }

  .details-card__img {
    width: 150px;
  }

  .details-card__name.ant-typography {
    margin-bottom: 0px;
  }
  
  .details-card__meta {
    > .ant-card-meta-detail {
      width: 100%;
    }
  }

  .details-card__title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }