.tower-form > .ant-form-item {
  margin-bottom: 0px;
}

.tower-projects-list.ant-collapse-item-active {
  > .ant-collapse-header > p {
    font-weight: 600;

    > span {
      background: #188fff42;
      padding: 0px 5px 0px 5px;
      border-radius: 5px;
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

.tower-projects-list__panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

span.badge-filter {
  width: 300px;
  margin-bottom: 1rem;
}

.badge-table-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.btn-renew.ant-btn-primary[disabled] {
  background: #e2e2e2 !important;
  color: #959393;
  border: 1px solid #e2e2e2 !important;
}
