.custom-steps {
  overflow-x: auto;
  margin-top: 0px;
  display: flex;
  margin-bottom: 8px;
  align-items: baseline;
  width: 100%;
  padding: 20px 0px 20px 0px;

  &--float-right {
    > .ant-steps {
      justify-content: flex-end;
    }
  }
}

.custom-step {
  > .ant-steps-item-container {
    > .ant-steps-item-content {
      width: 200px;
      > .ant-steps-item-title {
      font-size: 14px;
    }}
    > .ant-steps-item-tail {
      margin: 0 0 0 100px;
    }
    > .ant-steps-item-icon {
      margin-left: 98px;
    }
    > .ant-steps-item-tail::after {
      background-color: #dbdbdb !important;
    }
    > .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: #dbdbdb;
    }
  }
  &--current-step {
    > .ant-steps-item-container
      > .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot {
      background: orange;
      box-shadow: 0px 0px 0px 4px #ffa80840;
    }
  }
}
