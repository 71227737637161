.description__title {
  color: #3c3c3c;
}

.description__content {
  color: #8f8f8f;
}

.exit-interview-badge {
  &.ant-ribbon {
    font-weight: 600;
    height: 25px;
  }

  > .anticon {
    font-size: 20px;
    margin-top: 2px;
    &.anticon-alert {
/*       transform: rotateZ(45deg); */
      font-size: 22px;
      margin-top: 1px;
    }
  }

  &--voluntary:hover::after {
    content: "Voluntário";
  }
  
  &--involuntary:hover::after {
    content: "Involuntário";
  }

  &:hover::after {
    margin-left: 8px;
    margin-right: 8px;
    height: 25px;
    width: 100px;
  }
}
