#practice-card {
  border-radius: 12px;

  > .ant-card-body .generic-card__meta {
    background: #1976d2;
  }
}

.ant-modal.modal-cost-center {
  width: 700px !important;
}

optgroup {
  position: relative;
  top: -10px;
  flex-direction: column;
  flex: auto;
  margin-top: -17px !important;
  option {
    padding-left: 0;
  }
}

.rdl-control {
  padding-top: 20px !important;
}

.ant-space.title {
  font-weight: 500;
  font-size: 17px;
}

.react-dual-listbox {
  width: 650px;
}

.practice-title {
  margin-left: 4px;
  color: white;
  font-weight: 600;
  word-wrap: break-word;
  width: 70%;
}
