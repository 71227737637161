.competence-table-actions,
.competence-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.competence-actions > button:not(:last-child) {
  margin-right: 0.5rem;
}

.competence-table-actions .icon-button-wrapper:not(:last-child) {
  margin-right: 0.5rem;
}

.competence-title-cell {
  line-break: anywhere;
}

.competence-filter {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;

  .competence-name {
    height: fit-content;
    width: 300px;
  }
}

.competence-table {
  span::first-letter {
    text-transform: capitalize;
  }
}

.competence-table .ant-table-cell:nth-of-type(5){
  text-align: center;
  display: flex;
  justify-content: center;
}
