.icon-with-caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px 0px 16px 0px;
  min-height: 80px;

  &__caption {
    margin-top: auto;
  }

  > * > img {
    width: 42px;
    margin-bottom: 4px;
  }

  &__icon {
      margin-bottom: auto;
  }
}
