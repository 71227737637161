.district-members-infinite-scroll {
  height: 500px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.district-members-infinite-scroll-loading {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.district-members-empty {
  font-size: 25px;
  text-align: center;
}
