.capacity-table {
  > .ant-spin-nested-loading > .ant-spin-container > .ant-pagination {
    margin: 16px;
  }
}

.custom-summary {
  background: #e8e8e8;
  font-weight: 600;
}

.capacity-table__row {
  &--total {
    background: #fafafa;
  }
  &--collapsed {
    background: #fafafa;
  }
}

.capacity__button--expand.ant-btn {
  width: 20px;
  display: flex;
  justify-content: center;
  height: 20px;
  margin: 0;
  margin-right: 0px;
  padding: 0;
  background: #cfe0f1;
  align-items: center;
  border-radius: 5px;
  border: none;
  color: 'black';
  margin-right: 10px;

  &:focus,
  &:hover {
    color: black;
    background: #c6d6e6;
  }
}

.allocation-amount {
  width: 50px;
  margin-left: 5px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 0px;
}

.dark-grey-text {
  color: rgba(32, 32, 32, 0.85);
}